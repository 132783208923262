import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authData = this.getAuthData();

    if (authData.token) {
      request = this.cloneRequestWithAuthData(request, authData.token, authData.provider, authData.additionalData);
    }

    return next.handle(request);
  }

  private getAuthData(): { token: string | null, provider: string, additionalData?: any } {
    const telegramData = this.getTelegramAuthData();

    if (telegramData.token) {
      return telegramData;
    }

    const webData = this.getWebAuthData();

    return webData;
  }

  private getTelegramAuthData(): { token: string | null, provider: string, additionalData?: any } {
    const initData = window?.Telegram?.WebApp?.initData || null;
    const initDataUnsafe = window?.Telegram?.WebApp?.initDataUnsafe || null;

    if (initData) {
      console.log('Telegram is here');
      return {
        token: initData,
        provider: 'Telegram',
        additionalData: { telegramUserData: initDataUnsafe }
      };
    }

    if (!initData && !environment.production) {
      const mockToken = 'query_id=AAHVrwpPAgAAANWvCk-oLspV&user=%7B%22id%22%3A5621067733%2C%22first_name%22%3A%22Mohammad%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22mrebati_de%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1718701735&hash=1bb59846add9a4ea3c10bf0931c610c5ad1d345542abc5e06d69296f4d79de24';
      return {
        token: mockToken,
        provider: 'Telegram',
        additionalData: { telegramUserData: JSON.parse(decodeURIComponent(mockToken.split('&user=')[1].split('&')[0])) }
      };
    }

    return { token: null, provider: 'Telegram' };
  }

  private getWebAuthData(): { token: string | null, provider: string } {
    const token = localStorage.getItem('webAuthToken') || null;
    return {
      token,
      provider: 'Web'
    };
  }

  private cloneRequestWithAuthData(
    request: HttpRequest<any>,
    token: string,
    provider: string,
    additionalData?: any
  ): HttpRequest<any> {
    let updatedRequest = request.clone({
      setHeaders: {
        auth: token,
        provider
      }
    });

    if (additionalData) {
      updatedRequest = updatedRequest.clone({
        body: {
          ...request.body,
          ...additionalData
        }
      });
    }

    return updatedRequest;
  }
}
