import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'quiz', pathMatch: 'full'},
  { path: 'dashboard', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'features', loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'financial', loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule) },
  { path: 'diet-plans', loadChildren: () => import('./features/diet-plans/diet-plans.module').then(m => m.DietPlansModule) },
  { path: 'quiz', loadChildren: () => import('./features/quiz/quiz.module').then(m => m.QuizModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
